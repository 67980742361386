export const environment = {
  production: true,
  LINKEDIN_API_KEY: '77oj8s50xw1yt7',
  LINKEDIN_SECRET: 'W8tanXzQrWJpjH6y',
  LINKEDIN_REDIRECT_URL: 'https://devwebipie.me/register/linkedin-verif',
  LINKEDIN_SCOPE: "r_liteprofile%20r_emailaddress",
  backendDomainName: 'api.devwebipie.me',
  backendPort: 8000,
  backendProtocol: 'https',
  websiteDomainName: 'devwebipie.me',
  PORT: '',
  protocol: 'https'
};
